<template>
  <div id="web">
    Subscribed Instrument Updates
    <table style="width:20%">
      <tbody>
        <tr>
          <th>
            Instrument
          </th>
          <td>
            {{ subscribedInstruments.INSTRUMENTIDENTIFIER }}
          </td>
        </tr>
        <tr>
          <th>
            Open
          </th>
          <td>
            {{ subscribedInstruments.OPEN }}
          </td>
        </tr>
        <tr>
          <th>
            High
          </th>
          <td>
            {{ subscribedInstruments.HIGH }}
          </td>
        </tr>
        <tr>
          <th>
            Low
          </th>
          <td>
            {{ subscribedInstruments.LOW }}
          </td>
        </tr>
        <tr>
          <th>
            Close
          </th>
          <td>
            {{ subscribedInstruments.CLOSE }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style>
#web {
  margin-top: 60px;
}
</style>

<script>
import { gql } from 'graphql-tag'
const INSTRUMENTS_HISTORY_QUERY = gql`
  query instrumentsHistory($instruments:[String]){
    instrumentsHistory(instruments:$instruments) {
      instrument
      data {
        INSTRUMENTIDENTIFIER
        OPEN
        HIGH
        LOW
        CLOSE
        OPENINTEREST
        TRADEDQTY
        EXCHANGE
        LASTTRADETIME
      }
    }
  }
`
const INSTRUMENTS_SUBSCRIPTION = gql`
  subscription subscribedInstruments($instruments:[String]){
    subscribedInstruments(instruments:$instruments) {
      INSTRUMENTIDENTIFIER
      OPEN
      HIGH
      LOW
      CLOSE
      OPENINTEREST
      TRADEDQTY
      EXCHANGE
      LASTTRADETIME
    }
  }
`
const INSTRUMENTS_QUERY = gql`
  query subscribedInstruments($instruments:[String]){
    subscribedInstruments(instruments:$instruments) {
      INSTRUMENTIDENTIFIER
      OPEN
      HIGH
      LOW
      CLOSE
      OPENINTEREST
      TRADEDQTY
      EXCHANGE
      LASTTRADETIME
    }
  }
`
let subscribedInstruments =  {
  INSTRUMENTIDENTIFIER: '',
  OPEN:'0.0',
  HIGH:'0.0',
  LOW:'0.0',
  CLOSE:'0.0'
}
let instrumentsHistory = {}

export default {
  name: 'App',
  data: () => {
    return {
      subscribedInstruments
    } // This is where we store our graphql results.It starts out blank.
  },
  apollo: {
    instrumentsHistory: {
      query: INSTRUMENTS_HISTORY_QUERY,
      variables () {
          return { instruments: [
            "OPTIDX_BANKNIFTY_03NOV2021_CE_40100",
            "OPTIDX_BANKNIFTY_03NOV2021_PE_40100"
          ] }
        },
      update: (data) => {
        instrumentsHistory = data.instrumentsHistory
        console.log(instrumentsHistory)
        return instrumentsHistory
      }
    },

    subscribedInstruments: {
      query: INSTRUMENTS_QUERY,
      subscribeToMore: {
        document: INSTRUMENTS_SUBSCRIPTION
      ,
        // Variables passed to the subscription. Since we're using a function,
        // they are reactive
        variables () {
          return { instruments: [
             "OPTIDX_BANKNIFTY_03NOV2021_CE_40100",
            "OPTIDX_BANKNIFTY_03NOV2021_PE_40100"
          ] }
        },
        // Mutate the previous result
        updateQuery: (previousResult, { subscriptionData }) => {
           console.log(subscriptionData)
          return subscriptionData.data || subscribedInstruments
        }
      }
    }
  }
}
</script> 
